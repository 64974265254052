import "core-js/modules/es6.array.sort";
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Grid, Typography } from '@material-ui/core';
import * as Icons from 'mdi-material-ui';
import { useTheme } from '@material-ui/styles';
import { Link } from 'gatsby';

var Feature = function Feature(_ref) {
  var description = _ref.description,
      title = _ref.title,
      to = _ref.to,
      Icon = _ref.Icon;
  var theme = useTheme();
  return React.createElement(Grid, {
    item: true,
    md: 4,
    component: Link,
    style: {
      textDecoration: 'none'
    },
    to: to
  }, React.createElement(Typography, {
    align: "center",
    color: "textSecondary"
  }, React.createElement(Icon, {
    style: {
      height: '48px',
      width: '48px'
    }
  })), React.createElement(Typography, {
    variant: "h6",
    align: "center",
    color: "textPrimary",
    style: {
      marginBottom: theme.spacing(2)
    }
  }, title), React.createElement(Typography, {
    align: "center",
    color: "textPrimary"
  }, description));
};

var SiteIndex = function SiteIndex(_ref2) {
  var data = _ref2.data,
      location = _ref2.location;
  var theme = useTheme();
  var features = data.allMarkdownRemark.edges.filter(function (doc) {
    return doc.node.frontmatter.category === 'devops';
  }).sort(function (a, b) {
    return a.node.frontmatter.index > b.node.frontmatter.index ? 1 : -1;
  });
  return React.createElement(Layout, {
    location: location
  }, React.createElement(SEO, {
    title: "Welcome to SmallThings"
  }), React.createElement("div", {
    style: {
      backgroundColor: '#FFD966',
      marginBottom: '50px'
    }
  }, React.createElement(Typography, {
    variant: "h3",
    align: "center",
    style: {
      paddingTop: '150px',
      paddingBottom: '100px',
      margin: 'auto',
      width: '80%',
      lineHeight: '130%'
    }
  }, "Software development lifecycle backed by leading devops and compliance practices")), React.createElement(Grid, {
    container: true,
    spacing: 4,
    style: {
      width: '80%',
      margin: 'auto' //marginLeft: 'auto',
      //marginRight: 'auto',

    }
  }, features.map(function (feature, key) {
    return React.createElement(Feature, {
      key: key,
      Icon: Icons[feature.node.frontmatter.icon],
      title: feature.node.frontmatter.short,
      description: feature.node.frontmatter.description,
      to: feature.node.fields.slug
    });
  })));
};

export default SiteIndex;
export var pageQuery = "1273817401";